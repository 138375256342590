import React, { useEffect, useRef } from 'react';
import { OutlinedButton } from './customized_components';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography, } from '@mui/material';
import Tabstext from './tabstext';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';

function RecordingReminderDialog(props) {
    const sessionKey = "popupShown"; // Session key to track popup only once after login
    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';

    const handleRecordingTestClick = () => {
        props.clearTipCallback();
        props.handleRecordingTestClick();
    };

    const dismiss = () => {
        props.clearTipCallback();
    };

    return (
        <Dialog
            open={props.showTip}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    dismiss();
                }
            }}
            aria-labelledby="recording-reminder-dialog"
            PaperProps={{
                style: {
                    maxWidth: '50%',
                    border: '1px solid lightgray',
                    borderRadius: '10px',
                },
            }}
            disableEnforceFocus
        >
            <DialogTitle id="recording-reminder-dialog" dir={lang_dir} style={{fontSize:'22px'}}>
                {Tabstext.MakeTheMost[props.lang]}
            </DialogTitle>
            <DialogContent >
                <Typography
                    style={{
                        position: 'relative',
                        textAlign: 'justify',
                        fontStyle: 'normal',
                        textTransform: 'none',
                    }}
                    dir={lang_dir}
                >
                    {Tabstext.RecordingQualityExplained[props.lang]}
                </Typography>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 24px' }}>
                <Button variant='outlined' onClick={dismiss}>
                    {Tabstext.RemindMeLater[props.lang]}
                </Button>
                <Button variant='contained' startIcon={<MicIcon />} onClick={handleRecordingTestClick}>
                    <div style={{ marginRight: '10px' }}>
                        {Tabstext.RecordingTest[props.lang]}
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RecordingReminderDialog;
