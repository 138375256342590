import React, { useState, useEffect } from "react";
import { axiosInstance } from './axios_instance';
import Tabstext from './tabstext';
import Histogram from './histogram';
import { MediumArrowDropDownIcon, AudioMenuItem, } from './customized_components';
import {
    Button,
    Paper,
    Typography,
    Menu,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { ReactComponent as StudentsIcon } from '/static/icons/students.svg';

function TeachetDashboard(props) {
    // Retrieve data from sessionStorage or initialize as null
    const [myGroupsStatistics, setMyGroupsStatistics] = useState(JSON.parse(sessionStorage.getItem('myGroupsStatistics')) || null);
    //const [hist, setHist] = useState(JSON.parse(sessionStorage.getItem('hist')) || null);
    //const [binEdges, setBinEdges] = useState(JSON.parse(sessionStorage.getItem('binEdges')) || null);
    //const [histTitle, setHistTitle] = useState(sessionStorage.getItem('histTitle') || null);
    const [titles, setTitles] = useState(JSON.parse(sessionStorage.getItem('titles')) || null);
    const [TopUsers, setTopUsers] = useState(JSON.parse(sessionStorage.getItem('TopUsers')) || null);
    const [TopFailures, setTopFailures] = useState(JSON.parse(sessionStorage.getItem('TopFailures')) || null);
    const [AudioErrors, setAudioErrors] = useState(JSON.parse(sessionStorage.getItem('AudioErrors')) || null);
    const [ActiveGroups, setActiveGroups] = useState(JSON.parse(sessionStorage.getItem('ActiveGroups')) || null);
    const [ProgressGroups, setProgressGroups] = useState(JSON.parse(sessionStorage.getItem('ProgressGroups')) || null);

    const daysOptions = [7, 30, 90, 180, 365];
    const [pastdays, setPastdays] = useState(sessionStorage.getItem('pastdays') || daysOptions[1]);
    const [loading, setLoading] = useState(true);

    const [anchors, setAnchors] = useState([null, null, null]);
    const [openStates, setOpenStates] = useState([false, false, false]);
    const sessionKey = "dashboardDataFetched"; // Session key to track server fetch

    useEffect(() => {
        const storedMyGroupsStatistics = JSON.parse(sessionStorage.getItem('myGroupsStatistics'));
        const storedPastdays = sessionStorage.getItem('pastdays');
        const shouldFetchFromServer = !sessionStorage.getItem(sessionKey) || storedPastdays !== String(pastdays);

        if (shouldFetchFromServer) {
            // Fetch data from server
            axiosInstance
                .get('/api/my_groups_statistics/', { params: { pastdays } })
                .then((response) => {
                    //setBinEdges(response.data.histogram_bins);
                    setMyGroupsStatistics(response.data.MyGroupsStatistics);
                    //if (response.data.MyGroupsStatistics) {
                        //setHist(response.data.MyGroupsStatistics['All Groups']);
                        //setHistTitle(Tabstext.AllYourGroups[props.lang]);
                    //}
                    setTitles(translateHistTitles(response.data.MyGroupsStatistics));
                    setTopUsers(response.data.top_performers);
                    setTopFailures(response.data.top_failures);
                    setAudioErrors(response.data.top_audio_errors);
                    setActiveGroups(response.data.top_active_groups);
                    setProgressGroups(response.data.top_progress_groups);

                    // Save to sessionStorage
                    sessionStorage.setItem('binEdges', JSON.stringify(response.data.histogram_bins));
                    sessionStorage.setItem('myGroupsStatistics', JSON.stringify(response.data.MyGroupsStatistics));
                    //sessionStorage.setItem('hist', JSON.stringify(response.data.MyGroupsStatistics?.['All Groups']));
                    //sessionStorage.setItem('histTitle', Tabstext.AllYourGroups[props.lang]);
                    sessionStorage.setItem('titles', JSON.stringify(translateHistTitles(response.data.MyGroupsStatistics)));
                    sessionStorage.setItem('TopUsers', JSON.stringify(response.data.top_performers));
                    sessionStorage.setItem('TopFailures', JSON.stringify(response.data.top_failures));
                    sessionStorage.setItem('AudioErrors', JSON.stringify(response.data.top_audio_errors));
                    sessionStorage.setItem('ActiveGroups', JSON.stringify(response.data.top_active_groups));
                    sessionStorage.setItem('ProgressGroups', JSON.stringify(response.data.top_progress_groups));
                    sessionStorage.setItem('pastdays', pastdays); // Save pastdays for comparison

                    // Set session flag
                    sessionStorage.setItem(sessionKey, "true");
                });
            setLoading(false); // Set loading to false
        } else {
            // Load data from sessionStorage
            //setBinEdges(JSON.parse(sessionStorage.getItem('binEdges')));
            setMyGroupsStatistics(storedMyGroupsStatistics);
            //setHist(JSON.parse(sessionStorage.getItem('hist')));
            //setHistTitle(sessionStorage.getItem('histTitle'));
            setTitles(JSON.parse(sessionStorage.getItem('titles')));
            setTopUsers(JSON.parse(sessionStorage.getItem('TopUsers')));
            setTopFailures(JSON.parse(sessionStorage.getItem('TopFailures')));
            setAudioErrors(JSON.parse(sessionStorage.getItem('AudioErrors')));
            setActiveGroups(JSON.parse(sessionStorage.getItem('ActiveGroups')));
            setProgressGroups(JSON.parse(sessionStorage.getItem('ProgressGroups')));
            setLoading(false);
        }
    }, [pastdays]);

    useEffect(() => {
        if (myGroupsStatistics) {
            const translatedTitles = translateHistTitles(myGroupsStatistics);
            setTitles(translatedTitles);
            sessionStorage.setItem('titles', JSON.stringify(translatedTitles));
        }
    }, [props.lang]);

    const translateHistTitles = (dict) => {
        if (!dict)
            return null;
        var histTitles = [];
        var title;
        for (let key of Object.keys(dict)) {
            if (key === 'All Groups')
                title = Tabstext.AllYourGroups[props.lang];
            else if (/^\d+$/.test(key))
                title = Tabstext.GroupsOfYear[props.lang] + key;
            else
                title = key.length <= 18 ? key : '...' + key.slice(-18);
            histTitles.push(title);
        }
        return histTitles;
    };

    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';
    const lang_dir2 = props.lang !== 'he' ? 'right' : 'left';

    const handleClick = (index, event) => {
        // Open the menu at the specified index
        const updatedAnchors = [...anchors];
        updatedAnchors[index] = event.currentTarget;
        setAnchors(updatedAnchors);

        const updatedOpenStates = [...openStates];
        updatedOpenStates[index] = true;
        setOpenStates(updatedOpenStates);
    };

    const handleClose = (index) => {
        // Close the menu at the specified index
        const updatedAnchors = [...anchors];
        updatedAnchors[index] = null;
        setAnchors(updatedAnchors);

        const updatedOpenStates = [...openStates];
        updatedOpenStates[index] = false;
        setOpenStates(updatedOpenStates);
    };

    const handleMenuClick = (valIndex, menuIndex) => {
        // Update the selected pastdays value
        const selectedDays = daysOptions[valIndex];
        setPastdays(selectedDays);

        // Save the selected value to sessionStorage
        //sessionStorage.setItem('pastdays', selectedDays);

        // Close the menu at the specified index
        handleClose(menuIndex);
    };

    return (
        <div >
            {myGroupsStatistics &&
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '40px', margin: '40px' }}>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Paper className='longPaper' style={{ height: '130px', padding: '40px' }} >
                            <StudentsIcon />
                            <Button variant='contained' onClick={() => props.setContentCallback('my_groups')} >
                                <div style={{}}> {Tabstext.MyGroupsTab[props.lang]} </div>
                            </Button>
                        </Paper>
                    </div>

                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Paper className='longPaper' style={{ height: '200px' }} >
                            <Button variant='text' style={{ color: 'black' }} onClick={(event) => handleClick(1, event)} endIcon=<MediumArrowDropDownIcon /> >
                                <div dir={lang_dir} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin: '0 8px' }}>
                                    {Tabstext.TopPerformers[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                                </div>
                            </Button>
                            <Menu anchorEl={anchors[1]} open={openStates[1]} onClose={(event) => handleClose(1, event)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                                {daysOptions.map((val, index) => (
                                    <AudioMenuItem key={index} onClick={() => handleMenuClick(index, 1)} >
                                        {val}
                                    </AudioMenuItem>
                                ))}
                            </Menu>
                            <TopPerformersTable TopUsers={TopUsers} lang={props.lang} />
                        </Paper>
                    </div>

                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Paper className='longPaper' style={{ height: '200px' }} >
                            <Button variant='text' style={{ color: 'black' }} onClick={(event) => handleClick(2, event)} endIcon=<MediumArrowDropDownIcon /> >
                                <div dir={lang_dir} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin: '0 8px' }}>
                                    {Tabstext.TopGroups[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                                </div>
                            </Button>
                            <Menu anchorEl={anchors[2]} open={openStates[2]} onClose={(event) => handleClose(2, event)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                                {daysOptions.map((val, index) => (
                                    <AudioMenuItem key={index} onClick={() => handleMenuClick(index, 2)} >
                                        {val}
                                    </AudioMenuItem>
                                ))}
                            </Menu>
                            <TopGroupsTable ActiveGroups={ActiveGroups} ProgressGroups={ProgressGroups} lang={props.lang} />
                        </Paper>
                    </div>

                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Paper className='longPaper' style={{ height: '200px' }} >
                            <Button variant='text' style={{ color: 'black' }} onClick={(event) => handleClick(0, event)} endIcon=<MediumArrowDropDownIcon /> >
                                <div dir={lang_dir} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', margin: '0 8px' }}>
                                    {Tabstext.Attention[props.lang] + pastdays.toString() + Tabstext.Days[props.lang]}
                                </div>
                            </Button>
                            <Menu anchorEl={anchors[0]} open={openStates[0]} onClose={(event) => handleClose(0, event)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: lang_dir2, }}  >
                                {daysOptions.map((val, index) => (
                                    <AudioMenuItem key={index} onClick={() => handleMenuClick(index, 0)} >
                                        {val}
                                    </AudioMenuItem>
                                ))}
                            </Menu>
                            <AttentionTable TopFailures={TopFailures} AudioErrors={AudioErrors} lang={props.lang} />
                        </Paper>
                    </div>

                </div>
            }
            {!myGroupsStatistics &&
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '40px', margin: '40px' }}>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Paper className='longPaper' style={{ height: '200px' }} >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }} >
                                <StudentsIcon style={{ marginBottom: '40px' }} />
                                <Button variant='contained' onClick={() => props.setContentCallback('my_groups')} >
                                    <div style={{}}> {Tabstext.MyGroupsTab[props.lang]} </div>
                                </Button>
                            </div>
                        </Paper>
                    </div>
                </div>
            }
        </div>
    );
}

function TopPerformersTable({ TopUsers, lang }) {
    return (
        <Table style={{ borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
                {Object.entries(TopUsers).map(([user, progress], index) => (
                    <TableRow key={index}>
                        <TableCell className='smallCell' >
                            <Typography className='blue' >{user}</Typography>
                        </TableCell>
                        <TableCell className='smallCell' >
                            <Typography >{progress} {Tabstext.PassedExercises[lang]}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}


function TopGroupsTable({ ActiveGroups, ProgressGroups, lang }) {
    return (
        <Table style={{ borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
                {Object.entries(ActiveGroups).map(([group, active], index) => (
                    <TableRow key={index}>
                        <TableCell className='smallCell' >
                            <Typography className='blue' >{group}</Typography>
                        </TableCell>
                        <TableCell className='smallCell' >
                            <Typography >{active} {Tabstext.ActiveUsers[lang]}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableBody>
                {Object.entries(ProgressGroups).map(([group, progress], index) => (
                    <TableRow key={index}>
                        <TableCell className='smallCell' >
                            <Typography className='blue' >{group}</Typography>
                        </TableCell>
                        <TableCell className='smallCell' >
                            <Typography >{progress} {Tabstext.PassedExercises[lang]}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}


function AttentionTable({ TopFailures, AudioErrors, lang }) {
    return (
        <Table style={{ borderTop: '1px solid rgba(0,0,0,.1)', }} >
            <TableBody>
                {Object.entries(TopFailures).map(([user, failures], index) => (
                    <TableRow key={index}>
                        <TableCell className='smallCell' >
                            <Typography className='blue' >{user}</Typography>
                        </TableCell>
                        <TableCell className='smallCell' >
                            <Typography >{failures} {Tabstext.NotPassedRecordings[lang]}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableBody>
                {Object.entries(AudioErrors).map(([user, errors], index) => (
                    <TableRow key={index}>
                        <TableCell className='smallCell' >
                            <Typography className='blue' >{user}</Typography>
                        </TableCell>
                        <TableCell className='smallCell' >
                            <Typography >{errors} {Tabstext.BadRecordings[lang]}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}


export default TeachetDashboard;

//                        <Button onClick={() => handleStudentSelect(Users[0])} >
//                            <Typography style={{marginLeft:'0', fontSize:'20px'}}> {Users[0]} </Typography>
//                        </Button>
