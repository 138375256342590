import React, { useState, useEffect } from 'react';
import { axiosInstance, setupAxiosInterceptors } from './axios_instance';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PublicPage from './public_page';
import LoginForm from './login';
import RegisterForm from './register';
import RecordingForGuest from './recording_for_guest';
import Home from './home';
import SessionProvider from './SessionContext';
import Tabstext from './tabstext';

function App() {
    // fetched constants
    const [lang_options, setLangOptions] = useState(null);
    const [pitch_tolerance_range, setPitchToleranceRange] = useState(null);
    const [siteKey, setSiteKey] = useState(null);
    const [SolfegeLevels, setLevels] = useState([]);
    const [TheoryCourses, setCourses] = useState([]);
    const [SongClasses, setClasses] = useState([]);
    const [sessionSimeoutSec, setSessionSimeoutSec] = useState(null);
    const [allGroupIds, setAllGroupIds] = useState(null);

    // dynamic states
    const userLang = setUserLanguage();
    const [lang, setLang] = useState(userLang);
    const [authenticated, setAuthenticated] = useState(false);
    const [firstLogin, setFirstLogin] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state
    const [user, setUser] = useState(null); // New loading state
    const navigate = useNavigate();
    const location = useLocation();

    // fetch landing page data plus constants (pitch_tolerance_range)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/contents/');
                setLevels(response.data.SolfegeLevels);
                setCourses(response.data.TheoryCourses);
                setClasses(response.data.SongClasses);
                setAllGroupIds(response.data.allGroupIds);
                setSessionSimeoutSec(response.data.session_timeout_sec);
                setLangOptions(response.data.lang_options);
                setPitchToleranceRange(response.data.pitch_tolerance_range);
                setSiteKey(response.data.RecaptchaPubKey);
            } catch (error) {
                console.error("Error fetching contents:", error);
            } finally {
                // Ensure all updates are complete before setting loading to false
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setupAxiosInterceptors(() => window.location.href = '/');
    }, [navigate]);

    // check if authenticated
    useEffect(() => {
        axiosInstance.get('/api/authenticate/')
            .then((response) => {
                setAuthenticated(response.data);
            })
            .catch(() => {
                setAuthenticated(false);
            });
    }, []);

    // fetch user data
    useEffect(() => {
        if (authenticated) {
            axiosInstance.get('/api/user/', { 'params': {} })
                .then((response) => {
                    setUser(response.data.user);
                });
        }
    }, [authenticated]);

    // invoked from login and register
    const setAuthenticatedCallback = (val, firstTime) => {
        setAuthenticated(val);
        if (val === false) {
            sessionStorage.clear();
            setUser(null);
            navigate('/', { replace: true, state: {} });
        }
        if (val === true && firstTime !== null)
            setFirstLogin(firstTime)
    };

    const setUserCallback = (data) => {
        for (let key of Object.keys(data)) {
            if (key == 'tips_status') {
                let tip_key = Object.keys(data['tips_status'])[0];
                setUser(prevUser => ({
                    ...prevUser,
                    tips_status: {
                        ...prevUser.tips_status, // Copy the existing `tips_status` object
                        [tip_key]: { 'show': data['tips_status'][tip_key] }
                    },
                }));
            }
            else if (key == 'tips_reset') {
                setUser(prevUser => ({
                    ...prevUser,
                    tips_status: {}
                }));
            }
            else {
                setUser(prevUser => ({
                    ...prevUser, // Spread the previous user object to retain existing properties
                    [key]: data[key], // Update the specified key with the new value
                }));
            }
        }
        if (!user.teacher && data.location && data.school)
            setUser(prevUser => ({
                ...prevUser, 
                ['teacher']: 999,
            }));
    }

    useEffect(() => {
        if (authenticated) {
            if (location.pathname !== '/app/')
                navigate('/app/');
        } else {
            if (location.pathname === '/app/')
                navigate('/'); // app is not accessible to non authenticated users
            else {
                const searchParams = new URLSearchParams(location.search);
                let auth = searchParams.get("auth");
                if (auth)
                    setLang('he');
                navigate(location); // either public page, login or register
            }
        }
    }, [authenticated]);


    const langChangeCallback = (lang) => {
        setLang(lang);
        localStorage.setItem('lang', lang);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (authenticated && !user)
        return <div>Loading user data...</div>;

    return (
        <div id="appContainer" style={{ height: '100%' }}>
            <SessionProvider
                setAuthenticatedCallback={setAuthenticatedCallback}
                sessionTimeout={sessionSimeoutSec}
                expiredText={Tabstext.SessionExpired[lang]}
            >
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PublicPage
                                lang={lang}
                                lang_options={lang_options}
                                authenticated={authenticated}
                                langChangeCallback={langChangeCallback}
                                SolfegeLevels={SolfegeLevels}
                                SongClasses={SongClasses}
                            />
                        }
                    />
                    <Route
                        path="/app/*"
                        element={
                            <Home
                                user={user}
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                langChangeCallback={langChangeCallback}
                                lang_options={lang_options}
                                lang={lang}
                                pitch_tolerance={user?.pitch_tolerance}
                                pitch_tolerance_range={pitch_tolerance_range}
                                SolfegeLevels={SolfegeLevels}
                                TheoryCourses={TheoryCourses}
                                SongClasses={SongClasses}
                                firstLogin={firstLogin}
                                setUserCallback={setUserCallback}
                                allGroupIds={allGroupIds}
                            />
                        }
                    />
                    <Route
                        path="/login/*"
                        element={
                            <LoginForm
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                lang={lang}
                                RecaptchaPubKey={siteKey}
                            />
                        }
                    />
                    <Route
                        path="/register/*"
                        element={
                            <RegisterForm
                                lang={lang}
                                authenticated={authenticated}
                                setAuthenticatedCallback={setAuthenticatedCallback}
                                allGroupIds={allGroupIds}
                            />
                        }
                    />
                </Routes>
            </SessionProvider>
        </div>
    );
}

export default App;

function setUserLanguage() {
    let language = localStorage.getItem('lang');
    if (language)
        return language;
    else {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.includes('ro')) language = 'ro';
        else if (browserLanguage.includes('he')) language = 'he';
        else language = 'en';
        return language;
    }
}
