import React from 'react'
import { useState, } from "react";
import Tabstext from './tabstext';
import PointsDialog from './points_dialog';
import { Button, Paper, } from '@mui/material';
import { ReactComponent as SolfegeIcon } from '/static/icons/solfege.svg';
import { ReactComponent as SongsIcon } from '/static/icons/songs.svg';
import { ReactComponent as BooknoteIcon } from '/static/icons/booknote.svg';

function StudentDashboard(props) {
    const [showDialog, setShowDialog] = useState(false);
    const openDialog = () => {
        setShowDialog(true);
    };
    const closeDialog = () => {
        setShowDialog(false);
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
    <>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '40px', margin:'40px' }}>
            <div>
                <Paper className='longPaper' style={{ height: '120px', padding: '40px' }} >
                    <BooknoteIcon  />
                        <Button variant='contained' onClick={() => props.setContentCallback('my_progress')} >
                        {Tabstext.ViewYourProgressTab[props.lang]}
                    </Button>
                </Paper>
            </div>

            <div>
                <Paper className='longPaper' style={{ height: '120px', padding: '40px' }} >
                    <img src='/static/icons/bird1.svg' width="35" height="35"  />
                        <Button variant='text' onClick={() => openDialog()} >
                        {Tabstext.PerformanceScoreRulesTab[props.lang]}
                    </Button>
                </Paper>
            </div>
                <div>
                    <Paper className='longPaper' style={{ height: '120px', padding: '40px' }} >
                        <SolfegeIcon style={{}} />
                        <Button variant='contained' onClick={() => props.setContentCallback('solfege')} >
                            {Tabstext.PracticeSolfegeTab[props.lang]}
                        </Button>
                    </Paper>
                </div>

                <div>
                    <Paper className='longPaper' style={{ height: '120px', padding: '40px' }} >
                        <SongsIcon />
                        <Button variant='contained' onClick={() => props.setContentCallback('songs')} >
                            {Tabstext.PracticeSongsTab[props.lang]}
                        </Button>
                    </Paper>
                </div>
        </div>
        <PointsDialog lang={props.lang}
            showDialog={showDialog} closeDialog={closeDialog}
        />
    </>
    );
}

export default StudentDashboard;

