import React, { useEffect, useState, useRef } from 'react';
import Level from './Level';
import '../styles/puzzle.css';
import { Typography, } from '@mui/material';

function Puzzle(props) {
    const puzzleFolder = '/media/games/' + props.puzzleObj.descriptor.replace("_puzzle", "/");
    const puzzleTitle = props.puzzleObj.title;
    const configPath = puzzleFolder+'config.json';

    const [puzzleData, setPuzzleData] = useState(null); // State to hold the dynamically loaded config
    const [levels, setLevels] = useState([]);
    const [unlockedLevels, setUnlockedLevels] = useState([0]); // Only the first level is unlocked
    const [solvedLevels, setSolvedLevels] = useState([]); // Track solved levels
    const [puzzleCompleted, setPuzzleCompleted] = useState(false)    
    const audioRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        fetch(configPath)
            .then((response) => response.json())
            .then((data) => setPuzzleData(data))
            .catch((error) => console.error("Error loading puzzle data:", error));
    }, [configPath]);

    useEffect(() => {
        // Organize fragments into levels and set state

        if (puzzleData) {
            const levelsData = organizeFragments(puzzleData.frags);
            setLevels(levelsData);
            setUnlockedLevels([0]);
            setSolvedLevels([]);
        }
    }, [puzzleData]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load();  // Reload the audio when puzzleFolder changes
        }
    }, [puzzleFolder]);

    const organizeFragments = (fragments) => {
        const levels = {};

        fragments.forEach((fragData) => {
            const { fragPath, level, row, idx, anacrusis, complement, dur } = fragData;

            if (!levels[level]) {
                levels[level] = { level, stash: [], rows: {}, startTime: null, dur: null };
            }

            if (!levels[level].rows[row]) {
                levels[level].rows[row] = { row, fragments: [] };
            }

            // Push fragment with its metadata
            levels[level].stash.push({ fragPath, level, row, idx, anacrusis, complement, dur, startTime: null });
        });

        var t = 0.0;

        for (const [level, dictionary] of Object.entries(levels)) {
            // do something with `key` and `value`

            levels[level].startTime = t;
            levels[level].stash.forEach((frag) => {


                frag.startTime = t;
                t = t + frag.dur;
            })
            levels[level].dur = t - levels[level].startTime;
        }

        // Convert levels to array and ensure rows are arrays for rendering
        return Object.values(levels).map((level) => ({ ...level, rows: Object.values(level.rows), }));
    };

    // Function to handle level completion
    const handleLevelSolved = (levelIndex) => {
        setSolvedLevels([...solvedLevels, levelIndex]);

        const nextLevel = levelIndex + 1;
        if (nextLevel < levels.length) {
            setUnlockedLevels([...unlockedLevels, nextLevel]); // Unlock next level
        }
        else {
            setPuzzleCompleted(true);
            props.puzzleCompleteCallback(props.puzzleObj.pk);
        }
    };

    const playSegment = (startTime, dur) => {
        if (audioRef.current) {

            audioRef.current.pause();
            
            audioRef.current.currentTime = startTime * (60 / puzzleData.audio.tempo); // Set the start time
            audioRef.current.play(); // Start playing

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            // Stop after the duration
            timeoutRef.current = setTimeout(() => {

                if (audioRef.current) {
                    audioRef.current.pause(); // Pause the audio
                }
            }, dur * 1000 * (60 / puzzleData.audio.tempo)
            ); // Convert duration to milliseconds
        }
    };

    if (!puzzleData) {
        return <div>Loading puzzle data...</div>; // Render a loading state
    }
    return (
        <div className="App">
            <audio ref={audioRef}><source src={`${puzzleFolder}audio.mp3`} type="audio/mpeg" /></audio>
            <Typography style={{ textTransform: 'none', fontWeight: '450', fontSize: '24px', margin:'24px' }}>
                {puzzleTitle}
            </Typography>
            {levels.map((level, index) => (
                unlockedLevels.includes(index) && ( // Render only if the level is unlocked
                    <Level
                        level={level}
                        key={level.level}
                        hasAudio={puzzleData.audio.hasAudio}
                        meterBeatCount={puzzleData.meterBeatCount}
                        isUnlocked={unlockedLevels.includes(index)} // Only render unlocked levels
                        isSolved={solvedLevels.includes(index)} // Lock level if solved
                        onLevelSolved={() => handleLevelSolved(index)} // Mark the level as solved
                        playSegment={playSegment}
                    />
                )
            ))}


            {puzzleCompleted && (
                    <div className="congratulations-container">
                        <div style={{ display: 'flex' }}>
                            <h1 className="puzzle-h1">Congratulations!</h1>
                            <img src='/static/icons/bird1.svg' alt="Image" width="40" height="40" />
                        </div>
                        <h2 className="puzzle-h2">You have successfully completed the puzzle!</h2>
                    </div>
                )}

        </div>
    );
}

export default Puzzle;
