import React, { useRef, useState, useEffect} from 'react';

function Fragment({ idx, fragment, playSegment, isDraggable, classNam }) {

    const [imgWidth, setImgWidth] = useState('');

    const onDragStart = (e) => {
        playSegment(fragment.startTime, fragment.dur)
        e.dataTransfer.setData('fragment', JSON.stringify(fragment));
    };

    useEffect(() => {
        const img = new Image();  // Create an image object to get its intrinsic size
        img.src = `/media/games/${fragment.fragPath}`;

        img.onload = () => {
            const { naturalWidth, naturalHeight } = img;

            setImgWidth(naturalWidth);
        };


    }, [fragment]);

    return (
        <img
            className={classNam}
            src={`/media/games/${fragment.fragPath}`}
            style={{ width: `${imgWidth / 1516 * 90}%` }}
            alt={fragment.fragPath}
            draggable={isDraggable}
            onDragStart={onDragStart}
        />
    );
}

export default Fragment;
