import React from 'react';
import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import LangSelect from './lang_select';
import PublicPromo from './public_promo';
import PracticeDemo from './practice_demo';
import SongsContent from './songs_content';
import Tabstext from './tabstext';
import Introtext from './introtext';
import ContactUs from './contact_us';
import PdfViewer from './pdf_viewer_iframe';
import { LinkButtonTab } from './customized_components';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { Button, Typography, Tabs, Grid } from '@mui/material';
import { AppBar, Toolbar, } from '@mui/material';
import './styles/index.scss';

function PublicPage(props) {
    const navigate = useNavigate();
    const [value, setValue] = useState('home');
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const solfegePicSrc = props.lang === 'he' 
        ? "/static/art/Solfege_IL.jpg" 
        : (props.lang === 'en' ? "/static/art/Solfege_EN.jpg" : "/static/art/Solfege_RO.jpg");

    const practicePicSrc = props.lang === 'he'
        ? "/static/excerpts/eretz_he.png"
        : (props.lang === 'en' ? "/static/excerpts/unElefant_en.png" : "/static/excerpts/unElefant_ro.png");

    const tutorial_file_url = props.lang === 'he' 
        ? '/static/tutorials/סולפי_הדרכה.pdf'
        : '/static/tutorials/Tradition_and_modernity_in_music_education_2.1.pdf';

    const lang_dir = props.lang === 'he' ? 'rtl' : 'ltr';
    const displayTutorial = value === 'tutorial' ? '' : 'none';
    const gefenLink = 'https://apps.education.gov.il/tyhnet/public/#/tochniyot?q=31556';

    return (
        <Grid container direction="column" style={{ minHeight: '100vh' }}>
            < Header
                value={value}
                setValue={setValue}
                handleChange={handleChange}
                lang={props.lang}
                lang_options={props.lang_options}
                langChangeCallback={props.langChangeCallback}
            />
            {/* Main Content */}
            {value === 'home' && (
                <>
                    {/* Home section */}
                    <Grid item container className="paleBlueBg" spacing={4} style={{ padding: '60px' }}>
                        <Grid item xs={12} md={6} dir={lang_dir}>
                            <Typography variant="h1" className="blue" gutterBottom>
                                {Introtext.cultivating[props.lang]}
                            </Typography>
                            <Button variant='contained' color='secondary'
                                size="large" onClick={() => navigate(`/register`)} style={{ minWidth: '140px' }}>
                                {Tabstext.RegisterTab[props.lang]}
                            </Button>
                            {props.lang === 'he' && (
                                <Button variant='text' size="large" href={gefenLink} target="_blank" rel="noopener noreferrer">
                                    {'סולפי בגפ"ן'}
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%', maxWidth: '1000px', padding: '10px', backgroundColor: 'white', border: '1px solid lightgray', borderRadius: '10px' }}>
                                <img src={practicePicSrc} style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </Grid>
                    </Grid>
                    <PublicPromo lang={props.lang} />
                    <PracticeDemo lang={props.lang} />
                </>
            )}

            {/* Solfege section */}
            {value === 'solfeges' && (
                <Grid item container justifyContent="center" alignItems="center" style={{ padding: '10px' }}>
                    <img src={solfegePicSrc} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Grid>
            )}

            {/* Songs section */}
            {value === 'songs' && (
                <SongsContent SongClasses={props.SongClasses} lang_options={props.lang_options} lang={props.lang} />
            )}

            {/* About Us section */}
            {value === 'about_us' && (
                <Grid item container className="creamBgrd" direction="column" style={{ padding: '10px' }}>
                    <Typography variant="body1" dir={lang_dir}>
                        {Introtext.AboutUs_1[props.lang]}
                    </Typography>
                </Grid>
            )}

            {/* Contact Us section */}
            {value === 'contact_us' && <ContactUs lang={props.lang} />}

            {/* Tutorial section */}
            {value === 'tutorial' && (
                <Grid item container style={{ display: displayTutorial }}>
                    <PdfViewer pdfFileName={tutorial_file_url} />
                </Grid>
            )}
        </Grid>
    );
}

function Header(props) {
    const value = props.value;
    const setValue = props.setValue;
    const handleChange = props.handleChange;
    const navigate = useNavigate();

    return (
        <AppBar style={{ position:'sticky', backgroundColor: 'white' }} elevation={1}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left: Logo */}
                <Button onClick={() => setValue('home')} style={{ padding: 0 }}>
                    <SolfyLogo style={{ marginLeft: '20px' }} />
                </Button>

                {/* Center: Tabs */}
                <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary"
                    style={{ flexGrow: 1, minWidth: 0 }}>
                    <LinkButtonTab value="home" label={Tabstext.HomeTab[props.lang]} />
                    <LinkButtonTab value="solfeges" label={Tabstext.SolfegeContentTab[props.lang]} />
                    <LinkButtonTab value="songs" label={Tabstext.SongsContentTab[props.lang]} />
                    <LinkButtonTab value="tutorial" label={Tabstext.TutorialTab[props.lang]} />
                    <LinkButtonTab value="about_us" label={Tabstext.AboutUsTab[props.lang]} />
                    <LinkButtonTab value="contact_us" label={Tabstext.ContactUsTab[props.lang]} />
                </Tabs>

                {/* Right: Sign In & Language Select */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                    <Button variant="text" onClick={() => navigate(`/login`)} style={{ fontWeight: 700 }}>
                        {Tabstext.SignInTab[props.lang]}
                    </Button>
                    <LangSelect lang_options={props.lang_options} lang={props.lang} langChangeCallback={props.langChangeCallback} />
                </div>
            </Toolbar>
        </AppBar>
    );
}

//export default Header;

export default PublicPage;
