import React, { useState, useEffect, } from "react";
import { axiosInstance,  } from './axios_instance';
import ExercisePage from './exercise_page';
import Sidebar from './sidebar';
import Puzzle from './games/Puzzle';

import { Grid, } from '@mui/material';

function ExerciseMain(props) {
    const [exerciseData, setExerciseData] = useState({});
    const [context, setContext] = useState({});
    const [inClass, setInClass] = useState(false);
    const [pk, setPk] = useState(null);
    //const [completedPuzzle, setCompletedPuzzle] = useState(null);

    const setPkCallback = (val) => {
        setPk(val);
    }

    const inClassChangeCallback = (inClass) => {
        setInClass(inClass);
    };

    useEffect(() => {
        if (props.content == 'solfege' || props.content == 'theory' || props.content == 'songs') {
            setPk(props.content)
        }
    }, [props.content]);


    useEffect(() => {
        if (pk) {
            axiosInstance.get('/api/exercise/', { 'params': { 'pk': pk, 'studentUser': null, 'lang': props.lang } })
                .then((response) => {
                    if (response.data) {
                        setExerciseData(response.data);
                        setContext(response.data.context);
                    }
                });
        }
    }, [pk, props.user.as_student]); // retrieve all exercise data when pk changes

    const reloadExerciseData = () => {
        axiosInstance.get('/api/exercise/', { 'params': { 'pk': pk, 'studentUser': null, 'lang': props.lang } })
            .then((response) => {
                if (response.data) {
                    setExerciseData(response.data);
                    setContext(response.data.context);
                }
            });
    };

    const puzzleCompleteCallback = (puzzle_pk) => {
        axiosInstance.post('/api/puzzle_completed/', { pk: puzzle_pk, })
            .then((response) => {
                if (response.data) {
                    setContext(response.data.context);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    if (context == null || Object.keys(context).length === 0) {
        return null;
    }
    const sidebarWidth = !inClass ? 220 : 0;
    const isPuzzle = exerciseData.exe.descriptor.includes('puzzle');

    let puzzleObj = null;
    if (isPuzzle) {
        puzzleObj = exerciseData.exe;
    }

    return (
        <Grid
            style={{ flex: 1, display: 'grid', gridTemplateColumns: !inClass ? `${sidebarWidth}px 1fr` : 'none' }} >
            {!inClass &&
                <Sidebar 
                {...props}
                exercise={exerciseData.exe}
                setPkCallback={setPkCallback}
                category={context.category}
                unlockedExercises={context.unlocked_exercises}
                unlockedLessons={context.unlocked_lessons}
                solfegesPassStatus={context.solfegesPassStatus}
                user_id={context.user_id}
                user_group_id={context.user_group_id}
                />
            }
            {!isPuzzle &&
                <ExercisePage
                    {...props}
                    exerciseData={exerciseData}
                    setPkCallback={setPkCallback}
                    reloadExerciseData={reloadExerciseData}
                    inClass={inClass}
                    inClassChangeCallback={inClassChangeCallback}
                    sidebarWidth={sidebarWidth}
                />
            }
            {isPuzzle &&
                <Puzzle
                    puzzleObj={puzzleObj}
                    puzzleCompleteCallback={puzzleCompleteCallback}
                />
            }
        </Grid>
    )
}

export default ExerciseMain;
