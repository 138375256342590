import React from 'react'
import Tabstext from './tabstext';
import { ReactComponent as MicIcon } from '/static/icons/microphone.svg';
import { Button, Paper, Typography } from '@mui/material';

function Onboarding(props) {

    const lang = props.lang
    const lang_dir = lang=='he' ? 'rtl' : 'ltr';

    return (
        <div dir={lang_dir} style={{flex:1, margin:'40px'}} >
            <Typography variant='h2' gutterBottom >
                {Tabstext.WelcomeTab[lang]} {props.user.first_name}
            </Typography>
            <div className='paleBlueBg' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', marginTop: '20px' }} >
                <Typography variant='h4' gutterBottom >
                    {Tabstext.BeforeYouStart[lang]}
                </Typography>
            </div>
            <Paper 
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant='h6' style={{padding:'20px'}} >{Tabstext.RecordingQualityExplained[lang]}</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '12px 24px' }} >
                    <dButton
                        variant='outlined' onClick={props.clearOnboardingCallback}
                    >
                        {Tabstext.ContinueWithoutRecording[props.lang]}
                    </dButton>
                    < Button
                        variant='contained'
                        startIcon={<MicIcon />}
                        onClick={props.handleRecordingTestClick}
                    >
                        <div style={{ marginRight: '10px' }}>
                            {Tabstext.RecordingTest[props.lang]}
                        </div>
                    </Button>
                </div>
            </Paper>
        </div>
    );
}

export default Onboarding;