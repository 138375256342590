import { axiosInstance, setupAxiosInterceptors } from './axios_instance';
//import toastr from 'toastr';
//import 'toastr/build/toastr.min.css';
import {scoreCursorFrame, moveCursor} from './render_score'

var SetFeedbackCall = null;
var RecEndCall = null;

export function PutSetFeedbackCallback(val) {
    SetFeedbackCall = val;
}

export function makeBlob(chunks){
    let blob = new Blob(chunks, { type: 'audio/opus' });
    return blob;
}

export function submitData(blob, exe_pk, tempo, guide, clef, inClass) {
  var fd = new FormData();
  fd.append('audio_file', blob);
  fd.append('exercise', exe_pk);
  fd.append('tempo', tempo);
  fd.append('guide', guide);
  fd.append('clef', clef);
  fd.append('inClass', inClass);
  axiosInstance.post('/api/create_run/', fd, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(function (response) {
    var data = response.data;
    digest_feedback_data(data,true);
  })
  .catch(function (error) {
    console.error('Error submitting data:', error);
  });
}

export function digest_feedback_data(data, newRecording) {
    if (SetFeedbackCall !== null) {
        SetFeedbackCall(true, data, newRecording);
    }
}

export function render_lyrics_docx(lyrics_file_url,container) {
    const xhttp = new XMLHttpRequest();
    xhttp.onload = function () {
        var docxOptions = Object.assign(docx.defaultOptions, {
            useMathMLPolyfill: true,
            inWrapper: true
        });
        const blob = this.response;
        docx.renderAsync(this.response, container, null, docxOptions);
    }
    xhttp.open("GET", lyrics_file_url);
    xhttp.responseType = "blob";
    xhttp.send();
}

export function retrieve_past_recording(run_pk) {
    const mimeType = getSupportedMimeType();
    axiosInstance.get('/api/retrieve_run/', { 'params': { 'run_pk': run_pk, 'mimeType': mimeType }})
      .then(function (response) {
        var data = response.data;
        digest_feedback_data(data,false);
      })
      .catch(function (error) {
        console.log('Error retrieving past recording:', error);
      });
}

// globals
var Tempo = null;
var Delay = null;
var animated_audio = []
var animationId = null;
var MusicCursor = null;

export function frame(musicCursor, container, delay) {
    scoreCursorFrame(animated_audio, Tempo, musicCursor, container, delay);
    animationId = requestAnimationFrame(() => frame(musicCursor, container, delay));
}

export function Animate(audio,tempo, musicCursor, container, delay) {
    if(audio) {
        animated_audio = audio;
        MusicCursor = musicCursor;
        Tempo = tempo; // to support moveAudioCursor
        Delay = delay;
        animationId = requestAnimationFrame(() => frame(musicCursor, container, delay));
    }
}

export function StopAnimation() {
    if (animationId !== null) {
        cancelAnimationFrame(animationId);
        animationId = null;
    }
}

export function moveAudioCursor(click) {
//    const cursor = document.getElementById("music-cursor");
    moveCursor(click, MusicCursor, animated_audio, Tempo, Delay);
}

export function createAudioObjects(guideFiles,refFiles,handleAudioEnded,handleGuideEnded) {
    const audioRefObj = refFiles.map((filename) => {
        const audio = new Audio(filename);
        audio.onended = handleAudioEnded;
        return audio;
    });
    const audioGuideObj = guideFiles.map((filename) => {
        const audio = new Audio(filename);
        audio.onended = handleGuideEnded;
        return audio;
    });
    return [audioRefObj, audioGuideObj]
}

export const getSupportedMimeType = () => {
    const possibleTypes = [
        'audio/webm;codec=opus',
        'audio/mp4;codec=AAC', // for Safari
    ];
    for (const mimeType of possibleTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
            return mimeType;
        }
    }
    return '';
};

export function playDelayedAudio(playingObj, delaySec) {
    setTimeout(() => {
        playingObj.current.play().catch(error => {
            console.error('Error playing audio:', error);
        });
    }, delaySec*1000);

}

export function createAudio(audioObj1) {
    const audioContext = new (AudioContext || window.AudioContext || window.webkitAudioContext)();
    const source1 = audioContext.createMediaElementSource(audioObj1);
    const destination = audioContext.createMediaStreamDestination();
    source1.connect(destination);
    source1.connect(audioContext.destination);
    const combinedAudio = new Audio();
    combinedAudio.srcObject = destination.stream;
    combinedAudio.cleanup = () => {
        audioContext.close();
    };
    return combinedAudio;
}

export function createAudioMix(audioObj1, audioObj2, delay1, gain1) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const source1 = audioContext.createMediaElementSource(audioObj1);
    const source2 = audioContext.createMediaElementSource(audioObj2);

    const gain1Node = audioContext.createGain();
    gain1Node.gain.value = gain1; 

    const delayNode = audioContext.createDelay();
    delayNode.delayTime.value = Math.abs(delay1); 

    const destination = audioContext.createMediaStreamDestination();

    if (delay1 >= 0) {
        source1.connect(gain1Node).connect(audioContext.destination); // Connect audioObj1 through gain to the destination
        source2.connect(delayNode).connect(audioContext.destination); // Connect audioObj2 through delay to the destination
    } else {
        source1.connect(gain1Node).connect(delayNode).connect(audioContext.destination); // Connect audioObj1 through gain and delay to the destination
        source2.connect(audioContext.destination); // Connect audioObj2 directly to the destination
    }

    // Connect the nodes
    if (delay1 >= 0) { // delay obj2 to align with the delayed obj1 (recording)
        source1.connect(gain1Node).connect(destination); // Connect audioObj1 through gain to the destination
        source2.connect(delayNode).connect(destination); // Connect audioObj2 through delay to the destination
    }
    else { // rare case that obj1 (recording) had been cropped rather than delayed
        // Connect audioObj1 through gain and delay to the destination
        source1.connect(gain1Node).connect(delayNode).connect(destination);
        source2.connect(destination); // Connect audioObj2 directly to the destination
    }

    const combinedAudio = new Audio();
    combinedAudio.srcObject = destination.stream;
    combinedAudio.cleanup = () => {
        audioContext.close();
    };

    return combinedAudio;
}


export const fetchUserCountry = () => {
    let userCountry = localStorage.getItem("country");

    if (!userCountry || userCountry === "null") {
        return fetch("https://ipinfo.io/json?token=2e2de542a8bcac")
            .then(response => response.json())
            .then(jsonResponse => {
                userCountry = jsonResponse.country;
                localStorage.setItem("country", userCountry);
                return userCountry; // Return the fetched country
            })
            .catch(error => {
                console.error("Error fetching country:", error);
                return "Unknown"; // Return a fallback value
            });
    }

    return Promise.resolve(userCountry); // Wrap existing value in a Promise
};

export function filterChars(text,lang) {
    var filtered = null;
    if (lang=='he') {
        filtered = text.replace(/[A-Za-z]+/g, '');
        if (filtered.length < 3)
            filtered = text;
    }
    else {
        filtered = text.replace(/[\u0590-\u05FF]+/g, '');
    }
    return filtered;
};
