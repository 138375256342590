import React, { useState, useEffect } from "react";
import { useNavigate, } from 'react-router-dom';
import Tabstext from './tabstext';
import LangSelect from './lang_select';
import { Button, Tabs, } from '@mui/material';
import { VerticalTab, DynLockIcon } from './customized_components';
import { ReactComponent as SolfyLogo } from '/static/icons/solfyLogo.svg';
import { ReactComponent as HomeIcon } from '/static/icons/home.svg';
import { ReactComponent as SolfegeIcon } from '/static/icons/solfege.svg';
import { ReactComponent as SongsIcon } from '/static/icons/songs.svg';
import { ReactComponent as TheoryIcon } from '/static/icons/theory.svg';
import { ReactComponent as UserIcon } from '/static/icons/user.svg';
import { ReactComponent as BooknoteIcon } from '/static/icons/booknote.svg';
import { ReactComponent as StudentsIcon } from '/static/icons/students.svg';
import { ReactComponent as SolfegeIconDark } from '/static/icons/solfege_dark.svg';
import { ReactComponent as SongsIconDark } from '/static/icons/songs_dark.svg';
import { ReactComponent as TheoryIconDark } from '/static/icons/theory_dark.svg';
import { ReactComponent as BooknoteIconDark } from '/static/icons/booknote_dark.svg';
import { ReactComponent as StudentsIconDark } from '/static/icons/students_dark.svg';


function VerticalHeader(props) {
    const [value, setValue] = useState('dashboard');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setContentCallback(newValue);
    };
    useEffect(() => {
        if (props.content )
            setValue(props.content);
     }, [props.content]);

    const solfegeIcon = props.enableTabs ? <SolfegeIcon /> : <SolfegeIconDark />;
    const songsIcon = props.enableTabs ? <SongsIcon /> : <SongsIconDark /> ;
    const theoryIcon = props.enableTabs ? <TheoryIcon /> : <TheoryIconDark />;
    const studentsIcon = props.enableTabs ? <StudentsIcon /> : <StudentsIconDark />;
    const booknoteIcon = props.enableTabs ? <BooknoteIcon /> : <BooknoteIconDark />;
    const fullname = props.user.first_name + ' ' + props.user.last_name;

    return (
        <div className='verticalHeader' >
            <Button variant='text' onClick={() => navigate(`/`)} >
                <SolfyLogo />
            </Button>
            <div style={{ display:'flex', flex:1, flexDirection: 'column', justifyContent: 'space-between' }} >
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    indicatorColor='transparent'
                    style={{ borderRight: 'none', marginTop:'20px',}}
                    >
                    <VerticalTab label={Tabstext.DashboardTab[props.lang]} value="dashboard" icon=<HomeIcon/> />
                    <VerticalTab label={Tabstext.SolfegeTab[props.lang]} value="solfege" icon={solfegeIcon}
                        disabled={!props.enableTabs}
                    />
                    <VerticalTab label={Tabstext.TheoryTab[props.lang]} value="theory" icon={theoryIcon}
                        disabled={!props.enableTabs}
                    />
                    <VerticalTab label={Tabstext.SongsTab[props.lang]} value="songs" icon={songsIcon}
                        disabled={!props.enableTabs}
                    />
                    {props.user.as_student &&
                        <VerticalTab label={Tabstext.MyProgressTab[props.lang]} value="my_progress" icon={booknoteIcon}
                            disabled={!props.enableTabs}
                        />}
                    {!props.user.as_student &&
                        <VerticalTab label={Tabstext.MyStudentsTab[props.lang]} value="my_groups" icon={studentsIcon}
                            disabled={!props.enableTabs}
                        />}
                    <VerticalTab label={fullname} value="user" icon=<UserIcon />
                        style={{marginTop:'auto'}}
                    />
                </Tabs>
                <div mt="auto" style={{ textAlign: 'center', width: 'inherit' }}>
                    <LangSelect
                        lang_options={props.lang_options}
                        lang={props.lang}
                        langChangeCallback={props.langChangeCallback}
                        />
                </div>
            </div>
        </div>
    );
}

export default VerticalHeader;
