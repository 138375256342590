import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from './axios_instance';
import VerticalHeader from './vertical_header';
import Dashboard from './dashboard';
import ExerciseMain from './exercise_main';
import StudentDetails from './student_details';
import MyStudents from './mystudents';
import User from './user';
import { StopAnimation } from './exercise_utils';
import './styles/extras.css';

function Home(props) {
    const [scale, setScale] = useState(1);
    const [studentUser, setStudentUser] = useState(null);
    const [content, setContent] = useState(null);
    const [enableTabs, setEnableTabs] = useState(true);

    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const setScaleCallback = (val) => {
        setScale(val);
    };
    const enableTabsCallback = (val) => {
        setEnableTabs(val);
    };

    const windowSize = [window.innerWidth / scale, window.innerHeight / scale];
    useEffect(() => {
        const handleResize = () => {
            const newBaseFontSize = Math.min(window.innerWidth / 110, 16);
            document.documentElement.style.setProperty('--base-font-size', `${newBaseFontSize}px`);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [scale]);

    const handleNavigation = (path, username = null) => {
        setContent(path);
        navigate(`/app/${path}`);
        if (path === 'my_progress') {
            setStudentUser(username || props.user.username);
        }
        if (path !== 'solfege' && path !== 'theory' && path !== 'songs') {
            StopAnimation();
        }
    };

    // Update the content state when the location changes
    useEffect(() => {
        // Define the list of valid routes
        const validRoutes = [
            'dashboard',
            'solfege',
            'songs',
            'theory',
            'my_progress',
            'my_groups',
            'user',
        ];
        const path = location.pathname.replace('/app/', '').split('/')[0]; // Extract the base path
        if (validRoutes.includes(path)) {
            setContent(path);
        } else {
            setContent('dashboard'); // Default to dashboard if the path is invalid
        }
    }, [location]);

    if (!props.user)
        return null;

    return (
        <div
            className="homePage"
            style={{
                transform: `scale(${scale})`,
                width: `${100 / scale}%`,
                height: `${100 / scale}%`,
                transformOrigin: 'top left',
            }}
        >
            <VerticalHeader
                {...props}
                content={content}
                langChangeCallback={props.langChangeCallback}
                enableTabs={enableTabs}
                setContentCallback={handleNavigation}
            />
            <Routes>
                <Route
                    path="dashboard/*"
                    element={
                        <Dashboard
                            {...props}
                            windowSize={windowSize}
                            scale={scale}
                            setContentCallback={handleNavigation}
                            firstLogin={props.firstLogin}
                            enableTabsCallback={enableTabsCallback}
                            setScaleCallback={setScaleCallback}
                        />
                    }
                />
                <Route
                    path="solfege"
                    element={
                        <ExerciseMain
                            {...props}
                            content="solfege"
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                        />
                    }
                />
                <Route
                    path="songs"
                    element={
                        <ExerciseMain
                            {...props}
                            content="songs"
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                        />
                    }
                />
                <Route
                    path="theory"
                    element={
                        <ExerciseMain
                            {...props}
                            content="theory"
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                        />
                    }
                />
                <Route
                    path="my_progress"
                    element={
                        <StudentDetails
                            {...props}
                            username={studentUser}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                            teacherReview={false}
                        />
                    }
                />
                <Route
                    path="my_groups/*"
                    element={
                        props.user.teacher ? (
                            <MyStudents
                                {...props}
                                scale={scale}
                                setScaleCallback={setScaleCallback}
                                windowSize={windowSize}
                            />
                        ) : (
                            <Navigate to="dashboard" />
                        )
                    }
                />
                <Route
                    path="user"
                    element={
                        <User
                            {...props}
                            user={props.user}
                            windowSize={windowSize}
                            scale={scale}
                            setScaleCallback={setScaleCallback}
                        />
                    }
                />
                <Route path="*" element={<Navigate to="dashboard" />} />
            </Routes>
        </div>
    );
}

export default Home;
