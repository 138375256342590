import React from 'react';
import { useState, } from "react";
import { SolfyTextField, FormTitle } from './customized_components';
import { Button, Select, MenuItem, FormControl, InputLabel, } from '@mui/material';
import Tabstext from './tabstext';
import { axiosInstance,  } from './axios_instance';
import CloseIcon from '@mui/icons-material/Close';


function CreateGroupsForm(props) {
    const {
        initialFormData,
        setNumCreatedGroupsCallback,
        ageGroups,
        lang,
        onClose,
    } = props;

    const [formData, setFormData] = useState(initialFormData);
    const [groupExistError, setGroupExistError] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var groupname = formData.teachername+'.'+formData.location+'.'+formData.schoolname+'.'+formData.year+'.'+formData.classname;
        groupname = groupname.replace(/\s+/g, '.');
        axiosInstance.post('/api/create_group/',{groupname:groupname, agegroup:formData.agegroup})
        .then((response) => {
            if (response.status === 200) {
                if (response.data.message=='group created') {
                    setNumCreatedGroupsCallback(1);
                    setGroupExistError(false);
                    onClose();
                }
                else
                    setGroupExistError(true);
            }
        })
    };

    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    return (
        <div dir={lang_dir}>
            <FormTitle gutterBottom >
                {Tabstext.CreateGroupsTab[props.lang]}
            </FormTitle>
            <form onSubmit={handleSubmit}>
                <div >
                    <SolfyTextField
                        label={Tabstext.TeacherNameTab[lang]}
                        name="teachername"
                        value={formData.teachername}
                        onChange={handleChange}
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true, position: 'outside' }}
                    />
                    <SolfyTextField
                        label={Tabstext.LocationTab[lang]}
                        name="location"
                        value={formData.location}
                        required
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, position: 'outside' }}
                    />
                    <SolfyTextField
                        label={Tabstext.SchoolNameTab[lang]}
                        name="schoolname"
                        value={formData.schoolname}
                        required
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, position: 'outside' }}
                    />
                    <SolfyTextField
                        label={Tabstext.YearTab[lang]}
                        name="year"
                        value={formData.year}
                        required
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{ shrink: true, position: 'outside' }}
                    />
                    <SolfyTextField
                        label={Tabstext.ClassNameTab[lang]}
                        name="classname"
                        value={formData.classname}
                        required
                        onChange={handleChange}
                        margin="normal"
                        style={{ marginLeft: '16px', marginRight: '16px' }}
                    />
                    <FormControl fullWidth margin="normal" variant="standard">
                        <InputLabel >{Tabstext.AgeGroupSelectTab[props.lang]}</InputLabel>
                        <Select
                            name="agegroup"
                            value={formData.agegroup || ''}
                            onChange={handleChange}
                            required
                            displayEmpty
                        >
                            {ageGroups.map((age, index) => (
                                <MenuItem key={index} value={age}>
                                    {age}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        {Tabstext.AddTab[lang]}
                    </Button>
                    {groupExistError && (
                        <p style={{ color: 'red', marginTop: '10px' }}>
                            {Tabstext.GroupWithtNameExists[lang]}
                        </p>
                    )}
                </div>
            </form>
            <Button
                onClick={onClose}
                style={{ position: 'absolute', top: 0, [lang_dir === 'rtl' ? 'left' : 'right']: 0, marginTop: '10px' }}
            >
                <CloseIcon style={{ fontSize: '16px', fontWeight: 500, color: 'black' }} />
            </Button>
        </div>
    );
}

export default CreateGroupsForm;
