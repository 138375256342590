import Messages from './messages';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import Tabstext from './tabstext';

function FeedbackMessage(props) {
    const anchorRef = useRef(null);
    const [clickText, setClickText] = useState(null);

    var message = Messages[props.feedbackData.feedback_message][props.lang];
    if (props.feedbackData.performance_score && props.feedbackData.performance_score>0)
        message += ' ' + props.feedbackData.performance_score

    useEffect(() => {
        if (props.recTest) {
            if (props.feedbackData.message_color == 'red')
                setClickText(Tabstext.RecordAgain[props.lang]);
            else
                setClickText(Tabstext.MyRecordingGood[props.lang]);
        }
    }, [props.feedbackData.message_color]);

    const timeoutDuration = props.recTest ? Infinity : message.length * 70;

    useEffect(() => {
        setAnchorEl(anchorRef.current);
        // Set a timeout only if timeoutDuration is not Infinity
        let timer;
        if (timeoutDuration !== Infinity) {
            timer = setTimeout(() => {
                handleClose();
            }, timeoutDuration);
        }
        // Clear timeout on unmount
        return () => clearTimeout(timer);
    }, [props.feedbackData, timeoutDuration]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const messageColor = props.feedbackData.message_color;

    const recTestAction = () => {
        setAnchorEl(null);
        if (messageColor == 'red')
            props.handleFeedbackButton(0)
        else
            props.handleFeedbackButton(1)
    }

    const handleClose = () => {
        setAnchorEl(null);
        // Ensure focus is removed from any descendant of the Popover
        if (anchorRef.current) {
            anchorRef.current.focus();
        }
    };

    const x_svg_src = messageColor == 'red' ? '/static/icons/red_x.svg' : '/static/icons/green_x.svg';
    const lang_dir = props.lang=='he' ? 'rtl' : 'ltr';

    if (!props.display)
        return null;

    return (
        <div ref={anchorRef} style={{ position: 'relative' }} dir={lang_dir}>
            <Button variant='text' onClick={() => setAnchorEl(anchorRef.current)} >
                <img src='/static/icons/bird1.svg' alt="Image" width="30" height="30"  />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{ style: {maxWidth: '50%'} }}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableEnforceFocus
                >
                <Typography className={`feedbackMessage ${messageColor}`} dir={lang_dir}>
                    <img src='/static/icons/bird1.svg' alt="Image" width="35" height="35" style={{ margin: '0 8px' }} />
                    {props.recTest &&
                        <span style={{ verticalAlign: 'middle' }}>
                            {message}{' '}
                            <Button
                                onClick={() => recTestAction()}
                                style={{
                                    fontSize: 'inherit', // Match Typography font size
                                    fontWeight: 'bold', // Bold font
                                    textTransform: 'none', // Remove capitalization
                                    textDecoration: 'underline', // Add underline
                                    color: 'inherit', // Match Typography text color
                                    padding: 0, // Remove padding for inline look
                                    background: 'none', // Remove background
                                    minWidth: 'auto', // Prevent default button width
                                    lineHeight: 'inherit', // Match Typography line height
                                    display: 'inline', // Treat Button as inline text
                                    verticalAlign: 'baseline', // Align Button with text baseline
                                    marginLeft:'4px',
                                }}
                                disableRipple // Disable Material-UI ripple effect
                            >
                                {clickText}
                            </Button>
                        </span>
                    }
                    {!props.recTest &&
                    <>
                        { message }
                        <Button
                            variant="text"
                            onClick={() => setAnchorEl(null)}
                            style={{ minWidth: '0' }}
                        >
                            <img src={x_svg_src} alt="Image" width="20" height="20" />
                        </Button>
                    </>
                    }
                </Typography>
            </Popover>
        </div>
    );
}

export default FeedbackMessage



