import React from 'react';
import Fragment from './Fragment';

function Row({ row, onDrop, isSolved, playSegment }) {

    const onDropFragment = (e, insertIndex = null) => {
        //console.log(e, insertIndex)
        const fragment = JSON.parse(e.dataTransfer.getData('fragment')); // Retrieve and parse the fragment
        onDrop(fragment, insertIndex);
    };

    const onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    };


    return (
        // <h4 className="puzzle-h4"> Row {row.row}</h4>
        // Apply the solved-row class if the level is solved 
        <div className={`row ${isSolved ? 'solved-row' : ''}`}
            onDragOver={onDragOver}
            onDrop={onDropFragment}
            
        >
            {row.fragments.map((fragment, index) => (
                <Fragment
                    key={index}
                    idx={ index}
                    fragment={fragment}
                    
                    playSegment={playSegment}
                    isDraggable={!isSolved} // Disable dragging if the level is solved
                    onDropFragment={onDropFragment}
                    classNam={`fragment ${isSolved ? 'no-border' : ''}`} // Apply no-border class to remove fragment borders
                />

            ))}
        </div>
    );
}

export default Row;
